import * as React from 'react'
import ModLawyersTeaser, { ModLawyersTeaserProps } from './ModLawyersTeaser'

export interface ModLawyersGripdProps {
  content: ModLawyersTeaserProps[]
  ref?: React.MutableRefObject<undefined>
  minHeight?: number
}

export default class ModLawyersGrid extends React.Component<ModLawyersGripdProps, {}> {
  render() {
    const { content, minHeight, ref } = this.props

    const lawyerTeasers = content.map((data, index) => {
      return (
        <React.Fragment key={index}>
          <div
            style={minHeight && { minHeight: minHeight }}
            className={
              'cell small-12 medium-12 large-6 xlarge-4 xxlarge-4 ' +
              (index % 3 === 0 && index % 6 !== 0 ? 'large-offset-4 ' : '') +
              (index % 6 === 0 ? 'large-offset-2 ' : '') +
              (index % 4 === 0 && index % 8 !== 0 ? 'xlarge-offset-4 ' : '') +
              (index % 8 === 0 ? 'xlarge-offset-2 ' : '') +
              (index % 5 === 0 && index % 10 !== 0 ? 'xxlarge-offset-4 ' : '') +
              (index % 10 === 0 ? 'xxlarge-offset-2 ' : '')
            }
            ref={ref}
          >
            <ModLawyersTeaser content={data.content} label={data.label} />
          </div>
        </React.Fragment>
      )
    })

    return <div className="grid lawyers">{lawyerTeasers}</div>
  }
}
